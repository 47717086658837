<template>
    <div class="info-wrapper">
        <div style="text-align: center;margin-right: 50px">
            <el-upload
                class="avatar-uploader"
                :action="action"
                :headers="headers"
                accept=".jpg, .jpeg, .bmp, .gif, .png"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload">
                <img v-if="imageUrl" :src="imageUrl" class="avatar" alt="">
                <i v-else class="avatar-uploader-icon el-icon-plus"></i>
            </el-upload>
            <div class="upload-tips info">支持.jpg, .jpeg, .bmp, .gif, .png类型文件,5M以内</div>
        </div>
        <div>
            <p class="info">高级教师：{{ teacherName }}</p>
            <p class="info">联系方式：{{ phone }}
                <el-button type="text" size="medium" @click="change" style="margin-left: 20px">更改</el-button>
            </p>
        </div>
    </div>
</template>

<script>
    import { getToken } from "@/libs/auth";

    export default {
        name: "UserInfoScrollWrapper",
        data() {
            return {
                teacherName: '',
                phone: '',
                imageUrl: '',
                userId: '',
            }
        },
        computed: {
            action() {
                return 'api/operate/common/uploadFile'
            },
            headers() {
                return {
                    'Authorization': getToken()
                }
            }
        },
        created() {
            this.userId = this.$store.state.userId
            this.getUserInfo()
        },
        methods: {
            getUserInfo() {
                this._fet('/school/schoolUser/info', { id: this.userId }).then((res) => {
                    this.handleRes(res, () => {
                        this.teacherName = res.data.data.userName
                        this.phone = res.data.data.phone
                        if (res.data.data.userImg) {
                            this.imageUrl = res.data.data.userImg
                        }
                    })
                })
            },
            change() {
                this.$prompt('', '更改手机号', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputPattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
                    inputErrorMessage: '手机号格式不正确'
                }).then(({ value }) => {
                    this._fet('/school/schoolUser/edit', { id: this.userId, phone: value }).then((res) => {
                        this.handleRes(res, () => {
                            this.$message.success('更改手机号成功')
                            this.getUserInfo()
                        })
                    })
                }).catch(() => {
                })
            },
            handleAvatarSuccess(res, file) {
                if (res.code === '200') {
                    this._fet('/school/schoolUser/edit', { id: this.userId, userImg: res.data.url }).then((res1) => {
                        this.handleRes(res1, () => {
                            this.$message.success('修改头像成功')
                            this.getUserInfo()
                            this.$store.commit('setUserImg', res.data.url)
                        })
                    })
                }
            },
            beforeAvatarUpload(file) {
                // const isJPG = file.type === 'image/jpeg';
                const isLt2M = file.size / 1024 / 1024 < 5;
                const is50 = file.name.length < 50
                if (!is50) {
                    this.$message.error('上传文件名称不能超过50个字符!');
                    return false
                }
                // if (!isJPG) {
                //     this.$message.error('上传头像图片只能是 JPG 格式!');
                //     return false
                // }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 5MB!');
                    return false
                }
            },
            // 接口请求结果处理
            handleRes(res, fn) {
                if (res.data.code === '200') {
                    fn()
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            },
        },

    }
</script>

<style scoped lang="scss">
    .info-wrapper {
        display: flex;
        padding: 20px 50px;
        border-radius: 6px;
        margin: 10px;
        background-color: #fff;
        box-sizing: border-box;
        height: calc(100vh - 130px);
    }

    .info {
        margin-top: 10px;
        color: #5e6d82;
        white-space: nowrap;
    }

    .upload-tips {
        font-size: 14px;
    }

    .avatar-uploader {

        /deep/ .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 50%;
            cursor: pointer;
            position: relative;
            overflow: hidden;

            &:hover {
                border-color: #409EFF;
            }
        }

        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 178px;
            height: 178px;
            line-height: 178px;
            text-align: center;
        }

        .avatar {
            width: 178px;
            height: 178px;
            display: block;
        }
    }

</style>
