<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-08-14 11:24:51
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-08-22 08:56:10
 * @FilePath: /school_end/src/components/scrollWrapper/UserChangePassword.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="common-form content-bg">
        <div :class="{'common-form-layout': true }">
             <global-page-back
                detailPageTitle="个人中心"
                @handlerGlobalType="goback"
            >
            </global-page-back>
            <div class="common-form-wrapper">
                <div class="common-form-main common-form-layout--scroll summary-teacher-form">
                    <div class="common-form-view">
                        <el-form ref="ruleForm" label-position="right" label-width="120px">
                            <form-main-title title="基础信息" ></form-main-title>
                            <el-form-item label="头像" prop="headImg" style="margin-top: 13px" >
                                <div class="avatar-upload-wrap">
                                    <el-upload
                                        ref="avatarUpload"
                                        action="#"
                                        accept=".jpg, .jpeg, .bmp, .gif, .png"
                                        :show-file-list="false"
                                        :auto-upload="false"
                                        class="avatar-upload"
                                        :on-change="handleFileChange"
                                    >
                                        <div v-if="imageUrl" class="avatar-upload-img" @click.stop>
                                            <img :src="imageUrl" alt="">
                                            {{imageUrl}}
                                            <div v-if="isShowDelete" class="avatar-upload-img-operate">
                                                <i class="el-icon-upload2" @click.stop="handleUpload"></i>
                                                <i class="el-icon-delete" @click.stop="handleDelete"></i>
                                            </div>
                                        </div>
                                        <i v-else class="avatar-upload-icon el-icon-plus"></i>
                                    </el-upload>
                                    <div class="tips-wrap">
                                        <div class="tips-title">照片上传要求：</div>
                                        <div class="tips-content">1、照片用于门禁、话机等设备识别，请保证上传的照片为面部正面照片，人脸没有其他物体的遮挡；</div>
                                        <div class="tips-content">2、如佩戴眼镜请保证眼镜框没有遮挡眼睛；</div>
                                        <div class="tips-content">3、请不要佩戴口罩；</div>
                                        <div class="tips-content">4、支持格式为JPG，JPEG，GIF，BMP或PNG，大小不超过20M。</div>
                                    </div>
                                </div>
                            </el-form-item>

                            <form-data-item label="用户名" :value="userName" btn='修改用户名' @handleFormItemClick="handleUpdateUserName"></form-data-item>

                            <form-data-item label="登录手机号" :value="loginName"></form-data-item>

                            <form-data-item label="登录密码" value="********" btn='修改密码'  @handleFormItemClick="handleUpdatePassword"></form-data-item>

                            <form-data-item label="手机号" v-if="phone" :value="phone" btn="解绑" @handleFormItemClick="handleUnbindPhone"></form-data-item>

                            <form-data-item label="手机号" v-else value="未绑定" btn='绑定' @handleFormItemClick="handleBindPhone"></form-data-item>

                            <form-main-title title="第三方账号" > </form-main-title>

                            <form-data-item label="微信" v-if="thirdAccountInfo.wechatUnionId" :value="thirdAccountInfo.wechatNickName" btn='解绑'
                                @handleFormItemClick="handleUnbindClick('WeChat')">
                            </form-data-item>

                            <form-data-item label="微信" v-else value="未绑定" btn='绑定' @handleFormItemClick="handleBindClick('WeChat')" ></form-data-item>

                            <form-data-item label="钉钉" v-if="thirdAccountInfo.dingUnionId" :value="thirdAccountInfo.dingNickName" btn='解绑'
                                @handleFormItemClick="handleUnbindClick('DingTalk')">
                            </form-data-item>

                            <form-data-item label="钉钉" v-else value="未绑定" btn='绑定' @handleFormItemClick="handleBindClick('DingTalk')"></form-data-item>

                            <form-main-title title="关联员工信息" ></form-main-title>

                            <form-data-item label="姓名" :value="workerInfo.teacherName || '-'"></form-data-item>

                            <form-data-item label="性别" :value="handleSex(workerInfo.staffSex)"></form-data-item>

                            <form-data-item label="手机号" :value="workerInfo.staffPhone || '-'"></form-data-item>

                            <form-data-item label="工号" :value="workerInfo.staffOnlyCode || '-'"></form-data-item>

                            <form-data-item label="职位" :value="workerInfo.staffPostName || '-'"></form-data-item>

                            <form-data-item label="角色" :value="workerInfo.role"></form-data-item>
                        </el-form>
                    </div>
                </div>
            </div>
        </div>

        <dialog-wrapper class="iframeDialog" :dialogObj="dialogObj" @handleClose="handleDialogClose">
            <iframe
                :src=iframeSrc
                frameborder="0" scrolling="no" width="350px" height="500px"
            ></iframe>
        </dialog-wrapper>
        <!-- 绑定手机号 -->
        <dialog-wrapper :dialogObj="bindDialogObj" @handleClose="handleBindClose" @handleOpened="refreshCode">
            <bind-phone v-if="bindDialogObj.dialogVisible" ref='bindPhoneDialog' @handleBindClose="handleBindClose" :phone="phone" :bindPhoneStatus="bindPhoneStatus" @unbindPhone="unbindPhone"></bind-phone>
        </dialog-wrapper>
        <!-- 上传头像 -->
        <dialog-wrapper
            :dialogObj="cropperDialogObj"
            @handleClose="handleCropperClose"
        >
            <div class="cropper-content">
                <div class="cropper">
                    <vue-cropper
                        ref="cropper"
                        :img="option.img"
                        :info="option.info"
                        :outputType="option.outputType"
                        :fixed-box="option.fixedBox"
                        :auto-crop-width="option.autoCropWidth"
                        :auto-crop-height="option.autoCropHeight"
                        :can-scale="option.canScale"
                        :auto-crop="option.autoCrop"
                        :info-true="option.infoTrue"
                        :high="false"
                    />
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleCropperClose">取 消</el-button>
                <el-button :loading="cropperLoading" type="primary" @click="finish">确定</el-button>
            </span>
        </dialog-wrapper>
        <!-- 修改用户名 -->
        <dialog-wrapper :dialogObj="updateUserNameDialogObj" @handleClose="handleUserNameClose">
            <update-username :formData="updateUsernameForm" @handleSubmit="handleSubmitUsername" @handleClose="handleUserNameClose"></update-username>
        </dialog-wrapper>
        <!-- 修改密码 -->
        <dialog-wrapper :dialogObj="updatePassDiaObj" @handleClose="handlePasswordClose">
            <update-password @handleSubmit="handleSubmitPassword" @handleClose="handlePasswordClose" :phone="phone"></update-password>
        </dialog-wrapper>
    </div>
</template>

<script>
    import { DialogWrapper, throttle } from 'common-local';
    import { Encrypt } from '@/libs/secret'
    import { removeWeakPassword, removeToken } from '@/libs/auth';
    import { param2Obj } from '@/libs/utils';
    import { mapState } from "vuex";
    import store from "@/store";
    import GlobalPageBack from "./Sub/GlobalPageBack/index.vue";
    import { getToken } from "@/libs/auth";
    import { VueCropper } from "vue-cropper";
    import { CommonModel } from "@/models/Common";
    import FormSubmitBtn from "./Sub/FormSubmitBtn/index.vue";
    import FormMainTitle from "./Sub/FormMainTitle/index.vue";
    import FormDataItem from "./UserChangePassword/formDataItem.vue";
    import BindPhone from "./UserChangePassword/bindPhone.vue";
    import UpdateUsername from "./UserChangePassword/updateUserName.vue"
    import UpdatePassword from "./UserChangePassword/updatePassword.vue"
    import {getUserInfo } from "@/services";
    export default {
        name: "SystemPasswordScrollWrapper",
        components: {
            VueCropper,
            DialogWrapper,
            FormSubmitBtn,
            GlobalPageBack,
            FormMainTitle,
            FormDataItem,
            BindPhone,
            UpdateUsername,
            UpdatePassword
        },
        props: {
            visible: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            ...mapState({
                userId: state => state.userId,
                userName: state => state.userName,
                loginName: state => state.loginName,
                schoolId: state => state.schoolId,
                phone: state => state.phone,
            }),
            isShowDelete(){
                // 廊坊市第一中学(正式)
                let schoolIds =  ["d4f0ed4ec1c844d4c1fb554a107290ca"];
                return  !Boolean(schoolIds.includes(this.schoolId)) 
            },
            action() {
                return 'api/operate/common/uploadFile'
            },
            headers() {
                return {
                    'Authorization': getToken()
                }
            }
        },
        data() {
            return {
                bindPhoneStatus: 'bind',
                //修改用户名用的表单
                updateUsernameForm:{
                    id: '',
                    userName: ''
                },
                ruleForm: {
                    oldPassword: '',
                    newPassword: '',
                    affirmPassword: ''
                },
                thirdAccountInfo: {},
                dialogObj: {
                    title: '请扫码',
                    dialogVisible: false,
                    width: 'auto'
                },
                iframeSrc: '',
                updateUserNameDialogObj:{
                    title: '修改用户名',
                    dialogVisible: false,
                    width: '640px'
                },
                bindDialogObj: {
                    title: '绑定手机号',
                    dialogVisible: false,
                    width: '640px'
                },
                bindForm: {
                    schoolId: '',
                    password: '',
                    phone: '',
                    code: '',
                },
                updatePassDiaObj:{
                    title: '修改密码',
                    dialogVisible: false,
                    width: '640px'
                },

                changeLoading: false,
                changeTimer: null,
                changeCount: '',
                changeForm: {
                    schoolId: '',
                    phone: '',
                    code: '',
                    newPassword: '',
                },
                // phone: '',
                cropperLoading: false,
                imageUrl: '',
                cropperDialogObj: {
                    title: '裁剪图片',
                    dialogVisible: false,
                    width: '640px'
                },
                option: {
                    img: '', // 裁剪图片的地址
                    file: '',
                    info: false, // 裁剪框的大小信息
                    fixedBox: true,
                    autoCropWidth: 540,
                    autoCropHeight: 720,
                    canScale: true, // 图片是否允许滚轮缩放
                    autoCrop: true, // 是否默认生成截图框
                    infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
                    outputType: 'png',
                },
                workerInfo:{},//关联员工信息
            }
        },
        created() {
            this.init();
            this.getUserInfo();
        },
        beforeDestroy() {
            window.removeEventListener('message', this.handleBindAccount);
        },
        methods: {
            init(){
                this.$eventDispatch('setGlobalPageType', 'form')
                let param = param2Obj(location.search)
                if(this.$route.query.code) { // 微信
                    window.parent.postMessage('code:' + this.$route.query.code);
                }else if(param.authCode || this.$route.query.authCode) { // 钉钉
                    if(param.authCode) {
                        window.parent.postMessage('authCode:' + param.authCode);
                    }else if(this.$route.query.authCode) {
                        window.parent.postMessage('authCode:' + this.$route.query.code);
                    }
                }else {
                    window.addEventListener('message', this.handleBindAccount)
                    this.getUserBindThirdInfo()
                }
                this.updateUsernameForm = {
                    userId: this.userId,
                    userName: this.userName
                }
            },
            goback(){
                this.$router.push({
                    path: "/firstPage"
                })
                // window.history.go(-1);
            },
            /**
            * @Description 打开修改用户名弹窗
            * @DoWhat
            * @UseScenarios
            * @Attention 无
            * @Author 邵澳
            * @Date 2023/08/28 14:39
            **/
            handleUpdateUserName(){
                this.updateUserNameDialogObj.dialogVisible = true;
            },
            /**
             * @description: 提交修改用户名
             * @return {*}
             * @author: 邵澳
             * @Date: 2023-08-29 09:13:35
             */
            handleSubmitUsername(data){
                let form = {
                    id: data.userId,
                    userName: data.userName
                }
                this._fet('/school/schoolUser/updateUsername', form).then(async (res) => {
                    if(res.data.code == '200'){
                        this.$message.success('修改成功');
                        let userInfo = await this.getUserInfo();
                        console.log(222,userInfo)
                        this.$store.commit("setUserInfo", userInfo);
                        this.$forceUpdate();
                    }
                })
            },
            handleUserNameClose(){
                this.updateUserNameDialogObj.dialogVisible = false;
            },
            /**
             * @description: 打开手机号绑定弹框
             * @return {*}
             * @author: 邵澳
             * @Date: 2023-08-28 18:06:04
             */
            handleBindPhone() {
                this.bindPhoneStatus = 'bind'
                this.bindDialogObj.title = '绑定手机号';
                this.bindDialogObj.dialogVisible = true
            },
            /**
             * @description: 解绑手机号
             * @return {*}
             * @author: 邵澳
             * @Date: 2023-08-29 10:15:00
             */
            handleNoBindPhone(){

            },
            /**
            * @Description 关闭绑定手机号弹窗
            * @DoWhat
            * @UseScenarios
            * @Attention 无
            * @Author 韩熙昊
            * @Date 2022/12/14 15:07
            **/
            handleBindClose() {
                this.bindForm = this.$options.data().bindForm
                this.bindDialogObj.dialogVisible = false
            },
            /**
             * @description: 刷新绑定手机号弹框里边的数字验证码
             * @return {*}
             * @author: 邵澳
             * @Date: 2023-08-29 15:21:28
             */
            refreshCode(){
                this.$refs.bindPhoneDialog.referhCode();
            },
            /**
             * @description: 打开修改密码弹框
             * @return {*}
             * @author: 邵澳
             * @Date: 2023-08-29 15:30:54
             */
            handleUpdatePassword(){
                this.updatePassDiaObj.dialogVisible = true;
            },
            /**
             * @description: 修改密码弹框关闭
             * @return {*}
             * @author: 邵澳
             * @Date: 2023-08-29 15:34:42
             */
            handlePasswordClose(){
                this.updatePassDiaObj.dialogVisible = false;
            },
            /**
             * @description: 修改密码提交方法
             * @return {*}
             * @author: 邵澳
             * @Date: 2023-08-29 15:35:03
             */
            async handleSubmitPassword(){
                this.$message.success('修改密码成功!')
                this.logout()
            },
            changePwdByPwd(data){
                return new Promise(resolve => {
                    this._fet('/school/schoolUser/modifyPassword', {
                        oldPassword: Encrypt(data.oldPassword),
                        newPassword: Encrypt(data.newPassword),
                        affirmPassword: Encrypt(data.affirmPassword),
                        id: this.userId,
                        loginName: this.loginName
                    }).then((res) => {
                        resolve(res)
                    })
                })

            },
            /**
             * @Description: 获取个人信息
             * @DoWhat:
             * @UseScenarios:
             * @Attention:
             * @Author: xwn
             * @Date: 2023-02-20 09:36:53
             */
            getUserInfo() {
                return new Promise(resolve=>{
                    this._fet('/school/schoolUser/info', { id: this.userId }).then((res) => {
                        if(res.data.code == '200'){
                            console.log(res.data.data,'====')
                            let data = res.data.data;
                            this.workerInfo = data.staffMessage ? data.staffMessage : {};
                            this.workerInfo.teacherName = data.teacherName;
                            let role = data.roleList || [];
                            let arr = [];
                            role.forEach(item => {
                                arr.push(item.roleName)
                            })
                            this.workerInfo.role = arr.join(',')
                            if (res.data.data.userImg) {
                                this.imageUrl = res.data.data.userImg
                            }
                            resolve(data)
                        }
                    })
                })
            },
            getUserBindThirdInfo() {
                this._fet("/school/schoolUser/getUserBindThirdInfo").then((res) => {
                    console.log("[getUserBindThirdInfo]res:", res)
                    if(res.data.code == '200') {
                        this.thirdAccountInfo = res.data.data
                    }else {
                        this.$message.error(res.data.msg);
                    }
                })
            },
            async logout() {
                await removeWeakPassword()
                await removeToken()
                await this.$router.replace('/login')
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this._fet('/school/schoolUser/modifyPassword', {
                            oldPassword: Encrypt(this.ruleForm.oldPassword),
                            newPassword: Encrypt(this.ruleForm.newPassword),
                            affirmPassword: Encrypt(this.ruleForm.affirmPassword),
                            id: this.userId,
                            loginName: this.loginName
                        }).then((res) => {
                            if (res.data.code === '200') {
                                this.$message.success('修改密码成功!')
                                this.logout()
                            } else {
                                this.$message.error(res.data.msg)
                            }
                        })
                    } else {
                        return false;
                    }
                });
            },
            handleBindClick(type) {
                console.log('location:', location)
                // let url = location.origin + '/' + location.search
                let url = location.href + '?'
                switch (type) {
                    case 'DingTalk': // 钉钉
                        let clientId = process.env.VUE_APP_DINGTALK_THIRD_ID
                        this.iframeSrc = `https://login.dingtalk.com/oauth2/auth?redirect_uri=${encodeURIComponent(url)}&response_type=code&client_id=${clientId}&scope=openid&prompt=consent`
                        this.dialogObj.dialogVisible = true
                    break;
                    case 'WeChat': // 微信
                        let appId = process.env.VUE_APP_WECHAT_THIRD_ID
                        this.iframeSrc = `https://open.weixin.qq.com/connect/qrconnect?appid=${appId}&redirect_uri=${encodeURIComponent(url)}&scope=snsapi_login&state=undefined&login_type=jssdk&self_redirect=true&styletype=&sizetype=&bgcolor=&rst=`
                        this.dialogObj.dialogVisible = true
                    break
                }
                console.log("iframeSrc:", this.iframeSrc)
            },
            handleDialogClose() {
                this.dialogObj.dialogVisible = false
            },
            handleSex(val){
                if(val == '1'){
                    return '男'
                }else if(val == '2'){
                    return '女'
                }else{
                    return '-'
                }
            },
            // 绑定
            handleBindAccount: throttle(function(e){
                console.log('获取iframe传值:', e.data);
                try {
                    if(!e || (e && !e.data) || (e && e.data && e.data.indexOf(':') == -1)) {
                        return
                    }
                } catch (error) {
                    return
                }

                this.dialogObj.dialogVisible = false
                let msg = e.data.split(':')
                let authType = msg[0] == 'authCode' ? '2' : '1'
                let code = msg[1]
                this._fet("/auth/sc/bindUnionIdAfterLogin", {
                    code,
                    authCode: code,
                    authType
                }).then(res => {
                    console.log('[登录后绑定]接口返回：', res)
                    if(res.data.code == '200') {
                        this.$message.success('绑定成功')
                        this.getUserBindThirdInfo()
                    }else {
                        this.$message.error(res.data.msg)
                    }
                })

            }, 1500),
            // 解绑
            handleUnbindClick(type) {
                console.log(this.$store,'1111111111111')
                this.$confirm('确定解绑吗', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this._fet('/school/schoolUser/userUnBindUnionId', {
                        id: this.$store.state.userId,
                        authType: type == 'WeChat' ? '1' : (type == 'DingTalk' ? '2' : '')
                    }).then(res => {
                        if(res.data.code === '200') {
                            this.$message.success('解绑成功')
                            this.getUserBindThirdInfo()
                        }else {
                            this.$message.error(res.data.msg)
                        }
                    })
                }).catch(() => {
                });
            },
            /**
            * @Description 解绑手机号
            * @DoWhat
            * @UseScenarios
            * @Attention 无
            * @Author 韩熙昊
            * @Date 2022/12/14 14:39
            **/
            handleUnbindPhone(type) {
                this.bindPhoneStatus = 'unbind'
                this.bindDialogObj.title = '解绑手机号';
                this.bindDialogObj.dialogVisible = true;
            },
            unbindPhone (form) {
                this._fet('/school/schoolUser/unbindPhone', form).then((res) => {
                    if (res.data.code === '200') {
                        this.bindDialogObj.dialogVisible = false;
                        this.$message.success('解绑成功!')
                        this.$store.dispatch('getUserInfo');
                    } else {
                        this.$message.error(res.data.msg)
                    }
                })
            },

            /**
            * @Description 手机号脱敏
            * @DoWhat
            * @UseScenarios
            * @Attention 无
            * @Author 韩熙昊
            * @Date 2022/12/14 15:28
            **/
            noPassByMobile(str) {
                if (!['null', 'undefined'].includes(typeof str)) {
                    let pat = /(\d{3})\d*(\d{4})/;
                    return str.replace(pat, '$1****$2');
                } else {
                    return "";
                }
            },
            /**
            * @Description 获取修改密码验证码
            * @DoWhat
            * @UseScenarios
            * @Attention 无
            * @Author 韩熙昊
            * @Date 2022/12/14 15:26
            **/
            getChangePasswordCode() {
                this.$refs.changeForm.validateField('phone', (err) => {
                    if (!err) {
                        this.changeLoading = true
                        this._fet('/school/schoolAppCommon/sendSms', {
                            mobile: this.changeForm.phone,
                            smsType: 2
                        }).then((res) => {
                            console.log(res,'res.data===')
                            if (res.data.data.code === '200') {
                                this.$message.success("提交成功，验证码已发送")
                                if (!this.changeTimer) {
                                    const TIME_COUNT = parseInt(res.data.data.time)
                                    this.changeCount = TIME_COUNT;
                                    this.changeTimer = setInterval(() => {
                                        if (this.changeCount > 0 && this.changeCount <= TIME_COUNT) {
                                            this.changeCount--;
                                        } else {
                                            clearInterval(this.changeTimer);
                                            this.changeTimer = null;
                                        }
                                    }, 1000)
                                }
                            } else {
                                this.$message.error(res.data.data.info)
                            }
                            this.changeLoading = false
                        })
                    }
                })
            },
            /**
            * @Description 通过手机号修改密码
            * @DoWhat
            * @UseScenarios
            * @Attention 无
            * @Author 韩熙昊
            * @Date 2022/12/14 15:26
            **/
            handleSubmitChangePassword() {
                this.$refs.changeForm.validate((val) => {
                    if (val) {
                        let form = Object.assign({}, this.changeForm,
                            { newPassword: Encrypt(this.changeForm.newPassword) },
                            { schoolId: this.schoolId }
                        )
                        this._fet('/school/schoolUser/modifyPasswordByCode', form).then((res) => {
                            if (res.data.code === '200') {
                                this.$message.success('修改成功')
                                this.logout()
                            } else {
                                this.$message.error(res.data.msg)
                            }
                        })
                    }
                })
            },
            handleFileChange(file) {
                let flag = this.beforeAvatarUpload(file)
                if (flag !== false) {
                    this.$nextTick(() => {
                        // 上传预览图片  是个临时的缓存地址
                        this.option.img = URL.createObjectURL(file.raw)
                        this.option.file = file
                        this.cropperDialogObj.dialogVisible = true
                    })
                }
            },
            beforeAvatarUpload(file) {
                const types = ['.jpg','.JPG','.png','.PNG','.jpeg','.JPEG','.bmp','.BMP','.gif','.GIF']
                const isImg = types.includes(file.name.substring(file.name.lastIndexOf('.')))
                const isLt2M = file.size / 1024 / 1024 < 20;
                const is50 = file.name.length < 50
                if (!is50) {
                    this.$message.error('上传图片名称不能超过50个字符!');
                    return false
                }
                if (!isImg) {
                    this.$message.error('上传图片不符合格式!');
                    return false
                }
                if (!isLt2M) {
                    this.$message.error('上传图片大小不能超过20MB!');
                    return false
                }
            },
            finish() {
                this.$refs.cropper.getCropBlob((data) => {
                    this.cropperLoading = true
                    let newFile = new window.File([data], this.option.file.name, { type: data.type })
                    let formData = new FormData()
                    formData.append('file', newFile)
                    let commonModel = new CommonModel()
                    commonModel.uploadByPeople(formData).then(async (res) => {
                        if (res.data.code === '200') {
                            await this.handleAvatarSuccess(res.data)
                            this.handleCropperClose()
                        } else {
                            this.$message.error(res.data.msg)
                        }
                        setTimeout(() => {
                            this.cropperLoading = false
                        }, 300)
                    }).catch(() => {
                        setTimeout(() => {
                            this.cropperLoading = false
                        }, 300)
                    })
                })
            },
            async handleAvatarSuccess(res, file) {
                await this._fet("/school/schoolUser/updateBaseInfo", {
                    id: this.userId,
                    userImg: res.data.url,
                }).then((res1) => {
                    if (res1.data.code === "200") {
                        this.$message.success("修改头像成功");
                        this.imageUrl = res.data.url
                        this.getUserInfo();
                        this.$store.commit("setUserImg", res.data.url);
                    } else {
                        this.$message.error(res1.data.msg);
                    }
                });
            },
            handleCropperClose() {
                this.cropperDialogObj.dialogVisible = false
            },
            handleUpload() {
                this.$refs.avatarUpload.$refs['upload-inner'].handleClick()
            },
            handleDelete() {
                this.imageUrl = ""
                this.handleAvatarSuccess({
                    data: {
                        url: ''
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
::v-deep{
    .common-form-layout{
        .common-form-wrapper{
            .common-form-main{
                .common-form-view{
                    width: 885.5px !important;
                    margin-left: 40px;
                    .el-form{
                        .form-main-title{
                            width: 100%;
                        }
                        .el-form-data-item__label{
                            color: #2B2F33;
                            padding-right: 27px;
                        }
                        .avatar-upload-wrap {
                            display: flex;

                            .avatar-upload {
                                flex-shrink: 0;
                                align-self: flex-start;
                                width: 80px;
                                height: 106px;
                                border: 1px solid #d9d9d9;
                                box-sizing: border-box;
                                text-align: center;
                                overflow: hidden;

                                ::v-deep .el-upload--text {
                                    width: 80px;
                                    height: 106px;
                                }

                                .avatar-upload-img {
                                    position: relative;
                                    width: 80px;
                                    height: 106px;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                    }

                                    .avatar-upload-img-operate {
                                        display: flex;
                                        cursor: default;
                                        align-items: center;
                                        justify-content: center;
                                        position: absolute;
                                        left: 0;
                                        top: 0;
                                        width: 100%;
                                        height: 100%;
                                        opacity: 0;
                                        background: rgba(0, 0, 0, 0.5);
                                        transition: opacity .3s;

                                        .el-icon-upload2, .el-icon-delete {
                                            cursor: pointer;
                                            color: #ffffff;
                                            margin: 0 5px;
                                            font-size: 18px;
                                        }
                                    }

                                    &:hover .avatar-upload-img-operate {
                                        opacity: 1;
                                    }
                                }

                                &-icon {
                                    font-size: 16px;
                                    color: #d9d9d9;
                                    line-height: 106px;
                                    text-align: center;
                                }
                            }

                            .tips-wrap {
                                margin-left: 24px;
                                text-align: left;
                                color: #333333;
                                .tips-title {
                                    font-size: 14px;
                                    line-height: 20px;
                                    margin-bottom: 5px;
                                    font-weight: 600;
                                }

                                .tips-content {
                                    font-size: 12px;
                                    line-height: 20px;
                                }
                            }
                        }
                    }

                }

            }
        }

    }
    .cropper-content {
        display: flex;
        justify-content: center;

        .cropper {
            text-align: center;
            width: 540px;
            height: 720px;
        }

        .cropper-crop-box {
            background-color: #fff;
        }
    }
}
</style>
